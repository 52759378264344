import React from 'react';
import "./styles/Productivity.css"

function Productivity() {
    return (
        <div className="site-content">
            <main>
                <div className="productivity-page content-body">
                    <div className="section-title">
                        <h2>Productividad</h2>
                        <hr />
                    </div>
                    <div className="stats-grid">
                        <div className="stat">
                            <img src={process.env.PUBLIC_URL + "/img/icon1.png"} style={{ maxWidth: "150px" }} />
                            <div className="stat-content">
                                <span>
                                    <span>109</span>
                                </span>
                                <h4>Familias Apoyadas para realizar una Cirugía Fetal</h4>
                            </div>
                        </div>
                        <div className="stat">
                            <img src={process.env.PUBLIC_URL + "/img/icon4.png"} style={{ maxWidth: "150px" }} />
                            <div className="stat-content">
                                <span>
                                    <span>68</span>
                                </span>
                                <h4>Familias Apoyadas para realizar Estudios Genéticos</h4>
                            </div>
                        </div>
                        <div className="stat">
                            <img src={process.env.PUBLIC_URL + "/img/icon5.png"} style={{ maxWidth: "150px" }} />
                            <div className="stat-content">
                                <span>
                                    <span>544</span>
                                </span>
                                <h4>Pacientes atendidos en el programa de Neurodesarrollo</h4>
                            </div>
                        </div>
                        <div className="stat">
                            <img src={process.env.PUBLIC_URL + "/img/icon2.png"} style={{ maxWidth: "150px" }} />
                            <div className="stat-content">
                                <span>
                                    <span>172</span>
                                </span>
                                <h4>Becas otorgadas a médicos para asistir a cursos en Medicina Fetal</h4>
                            </div>
                        </div>
                        <div className="stat">
                            <img src={process.env.PUBLIC_URL + "/img/icon3.png"} style={{ maxWidth: "150px" }} />
                            <div className="stat-content">
                                <span>
                                    <span>776</span>
                                </span>
                                <h4>Ecografías Fetales Realizada</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default Productivity;
import React from 'react';
import "./styles/Testimony.css"

function Testimony() {
    return (
        <>
            <div className="site-content">
                <main>
                    <div className="content-body neuro-page">
                        <div className="section-title">
                            <h2>Testimonios</h2>
                            <hr/>
                        </div>
                        <div>
                            <h2 className="main-header">
                                <hr/>
                                <div>Avance médico salva vidas en México gracias a la cirugía fetal</div>
                            </h2>
                            <div>
                                <p>
                                    La microcirugía fetal de avanzada en el céntrico estado mexicano de Querétaro, salva vidas desde el vientre materno a bebés con
                                    malformaciones desde el embarazo.
                                </p>
                                <p>
                                </p>
                            </div>
                            <section style={{display: "flex", justifyContent: "center", width: "100%", margin: "2em auto"}}>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/xPV6jAe4Bv8" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"/>
                            </section>
                        </div>
                        <div>
                            <h2 className="main-header">
                                <hr />
                                <div>Médicos mexicanos logran microcirugía fetal que corrige espina bífida y evita discapacidad</div>
                            </h2>
                            <div>
                                <p>
                                    Con 14 semanas de gestación, Alma Quintero recibió la peor noticia que una madre podría escuchar, su médico le recomendó detener
                                    el embarazo, debido a que su bebé presentaba espina bífida, un defecto embrionario en la espina dorsal que causa hidrocefalia,
                                    discapacidad motora y daño neurológico.

                                    <a href="https://www.youtube.com/hashtag/noticiasmilenio">#NoticiasMilenio</a>
                                </p>
                                <p>
                                </p>
                            </div>
                            <section style={{display: "flex", justifyContent: "center", width: "100%", margin: "2em auto"}}><iframe width="560" height="315" src="https://www.youtube.com/embed/-BkDTssxUX4" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
                            </section>
                        </div>
                        <div>
                            <h2 className="main-header"><hr />
                                <div>Íker, un milagro de la ciencia</div>
                            </h2>
                            <div>
                                <p>
                                    Alma no dejó de luchar, pese a un fatal diagnóstico durante su embarazo. Ahora su bebé es considerado un milagro de la ciencia.
                                </p>
                                <p>
                                </p>
                            </div>
                            <section style={{display: "flex", justifyContent: "center", width: "100%", margin: "2em auto"}}>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/Nzf8nfBSE0Y" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
                            </section>
                        </div>
                        <div>
                            <h2 className="main-header">
                                <hr />
                                <div>Becas Curso Neurosonografía</div>
                            </h2>
                            <div>
                                <p>Fundación Medicina Fetal</p>
                            </div>
                            <section style={{display: "flex", justifyContent: "center", width: "100%", margin: "2em auto"}}>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/Qo1BcZnxVK0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
                            </section>
                        </div>
                        <div>
                            <h2 className="main-header">
                                <hr />
                                <div>Testimonio Maricela Tineo López</div>
                            </h2>
                            <div>
                                <h1>Diagnóstico Espina Bífida</h1>
                                <p>
                                </p>
                            </div>
                            <section style={{display: "flex", justifyContent: "center", width: "100%", margin: "2em auto"}}>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/tEVZKmiFNbw" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
                            </section>
                        </div>
                        <div>
                            <h2 className="main-header">
                                <hr />
                                <div>Testimonio Julissa Reynaga Montaño </div>
                            </h2>
                            <div>
                                <h1>Diagnóstico Hidrops + Hidrotórax</h1>
                                <p>
                                    <a href="https://www.youtube.com/results?search_query=%23Colima">#Colima</a>
                                    <a href="https://www.youtube.com/results?search_query=%23Quer%C3%A9taro">#Querétaro</a>&nbsp;<a href="https://www.youtube.com/results?search_query=%23Hidrops">#Hidrops</a></p><p></p></div><section style={{display: "flex", justifyContent: "center", width: "100%", margin: "2em auto"}}>
                                        <iframe width="560" height="315" src="https://www.youtube.com/embed/ADghqVUS5fg" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture">
                                    </iframe>
                            </section>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}

export default Testimony;
import React from 'react';
import "./styles/RogelioCruz.css"

function RogelioCruz() {
    return (
        <div className="site-content">
            <main>
                <div className="content-body doctor-page">
                    <div className="section-title">
                        <h2>Dr. Rogelio Cruz Martínez, MD, PhD.</h2>
                        <hr />
                    </div>
                    <div className="doctor-profile row">
                        <img src={process.env.PUBLIC_URL + "/img/Rogelio.bmp"} style={{maxHeight: "250px"}}/>
                        <div className="content">
                            <p>
                                Responsable de la Unidad de Cirugía Fetal del Hospital de Especialidades del Niño y la Mujer de Querétaro desde 2013, Investigador nacional
                                nivel 3 del Sistema Nacional de Investigadores (SNI) del Consejo Nacional de Ciencia y Tecnología (CONACYT).
                            </p>
                            <p> Especialista en Medicina y Cirugía Fetal egresado del Hospital Clinic de Barcelona, España (2007-2011), cuenta con un Doctorado Europeo
                                por la Universidad de Barcelona. Pionero de la cirugía fetal en México, con una experiencia de más de 600 cirugías fetales en 7 años.
                            </p>
                            <p>
                                Ha realizado por primera vez en México las siguientes cirugías: valvuloplastía cardiaca fetal, oclusión traqueal endoscópica fetal,
                                cistoscopía fetal, ablación láser de tumores fetales); y ha participado en el diseño de algunos de los nuevos tratamientos fetales a
                                nivel mundial (Broncoscopía fetal e intubación traqueal fetoscópica).
                            </p>
                            <p>
                                Ha sido ponente en más de 100 ocasiones en cursos, conferencias y congresos nacionales y mundiales de la Fetal Medicine Foundation of London
                                y de la International Society of Ultrasound in Obstetrics and Gynecology (ISUOG) en diferentes países.
                            </p>
                            <p>
                                Hapublicado más de 60 artículos científicos en diferentes revistas internacionales con impact factor, es miembro del comité científico
                                del Doctorado Europeo en Medicina Fetal “Erasmus Mundus”.
                            </p>
                            <p>
                                Es revisor científico de las revistas:
                            </p>
                            <ol>
                                <li>
                                    <p>
                                        Fetal Diagnosis and Therapy
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Obstetrics and Gynecology
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        American Journal Obstetrics and Gynecology
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Ultrasound Obstetrics and Gynecology
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Prenatal Diagnosis
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Pediatrics
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Best Practice Research Clinic Obstetrics and Gynecology
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Gynecology Obstetrics Investigation
                                    </p>
                                </li>
                            </ol>
                            <p>
                            </p>
                        </div>
                    </div>
                    <h2 className="main-header">
                        <hr />
                        <div>Trayectoria Académica</div>
                    </h2>
                    <p>
                        <b>
                            Ginecología y Obstetricia
                        </b>
                        , Unidad Médica de Alta Especialidad (UMAE) Gineco 4 “Luis Castelazo Ayala”, Centro Médico Siglo XXI, Instituto Mexicano del Seguro Social
                        (IMSS), Ciudad de México (2003-2007).
                    </p>
                    <p>
                        <b>
                            Ginecología y Obstetricia
                        </b>
                        , Unidad Médica de Alta Especialidad (UMAE) Gineco 4 “Luis Castelazo Ayala”, Centro Médico Siglo XXI, Instituto Mexicano del Seguro Social (IMSS),
                        Ciudad de México (2003-2007).
                    </p>
                    <p>
                        <b>
                            Medicina y Cirugía Fetal.
                        </b>
                        Hospital Clínic, Universidad de Barcelona, España (2007-2011).
                    </p>
                    <p>
                        <b>
                            Fellowship en el grupo de Investigación en Medicina Fetal
                        </b>
                        , Institut d´Investigacions Biomèdiques Agustí Pi i Sunyer (IDIBAPS), Hospital Clìnic, Barcelona, España (2007-2011).
                    </p>
                    <p>
                        <b>
                            Máster en Metodología y Estadística en Ciencias de la Salud.
                        </b>
                        Universidad Autónoma de Barcelona, España.
                    </p>
                    <p>
                        <b>
                            Doctorado Europeo por la Universidad de Barcelona, España.
                        </b>
                        Tesis doctoral “Fetal brain and cardiac Doppler parameters to indentify fetuses with late-onset intrauterine growht restriction at risk of adverse
                        perinatal and neurobehavioral outcome”.
                    </p>
                    <p>
                        <b>
                            Investigador Nacional nivel 3 del Sistema Nacional de Investigadores (SNI) del Consejo Nacional de Ciencia y Tecnología (Conacyt)
                        </b>
                        , México.
                    </p>
                    <p>
                        <b>
                            Profesor revisor de publicaciones
                        </b>
                        en la revista “Fetal Diagnosis and Therapy”.
                    </p>
                    <h2 className="main-header">
                        <hr />
                        <div>Asociaciones Médicas</div>
                    </h2>
                    <ul>
                        <li>
                            <p>
                                Colegio Mexicano de Especialistas en Ginecología y Obstetricia (COMEGO)
                            </p>
                        </li>
                        <li>
                            <p>Colegio de Ginecología y Obstetricia de Querétaro</p>
                        </li>
                        <li>
                            <p>Miembro de la “International Society in Ultrasound in Obstetrics and Gynecology (ISUOG)”</p>
                        </li>
                    </ul>
                    <p>
                    </p>
                    <h2 className="main-header">
                        <hr />
                        <div>Publicaciones</div>
                    </h2>
                    <p>
                        Ha publicado varios artículos originales en revistas internacionales con impact factor (34 en PubMed), 45 abstracts y 20 capítulos en los siguientes
                        libros de Medicina Fetal.
                    </p>
                    <ol>
                        <li>
                            <p>
                                OBSTETRIC IMAGING (A Volume in the Expert Radiology Series). Elsevier Inc. Joshua Copel, MD.
                            </p>
                        </li>
                        <li>
                            <p>
                                Cuidados
                                Críticos en la paciente Obstétrica 2010. Carmen Fernández López de
                                Hierro. 2010 ABBOTT LABORATORIES, S.L. Editorial: ENE EDICIONES.
                                ISBN:978-84-85395-82-8.</p></li><li><p>Doppler en Medicina Fetal. Técnica y
                                    aplicación clínica. 1ª ed. Barcelona, España Editorial Panamericana,
                                    2010; ISBN 978-84-98352-73-3. E. Gratacos, F.Figueras, B.Puerto,
                                    E.Hernandez.</p></li><li><p>Terapia Fetal (Indicaciones actuales y claves para
                                        el diagnóstico precoz). E. Gratacos. 1ª ed. Barcelona, España. Editorial
                                        Mayo, 2008; ISBN: 978-84-96792-96-8.</p></li></ol><p></p>
                    <h2 className="main-header">
                        <hr />
                        <div>Distinciones</div>
                    </h2>
                    <p>2009. En Septiembre 2009 recibe en Hamburgo, Alemania el premio
                        “Young Investigator”, durante el congreso Mundial de la Sociedad
                        Internacional de Ultrasonido en Ginecología y Obstetricia ISUOG (19th World Congress on Ultrasound in Obstetrics and Gynecology).</p>
                    <p>En 2010 y 2011 recibe de manera consecutiva el premio a la mejor presentación oral “Free Communication Award” en el congreso mundial de
                        la Sociedad Internacional de Ultrasonido en Ginecología y Obstetricia ISUOG en Octubre 2010 en Praga, República Checa y en Los Angeles,
                        California, USA</p><p>En 2013, recibe el nombramiento de “Miembro Honorario” de la Sociedad Peruana de Obstetricia y Ginecología y en Mayo 2014
                            recibe el mismo nombramiento de “Miembro Honorario” la Federación Colombiana de Obstetricia y Ginecología (FECOLSOG) al distinguirlo como un
                            ejemplo de vida y profesionalismo en esta especialidad.</p><p>En Septiembre 2015 recibe el reconocimiento de Discovery Chanel por su
                                trayectoria en el desarrollo de la cirugía fetal dentro del proyecto “Celebrando México 2015”.</p><p>En Mayo 2019 recibe el nombramiento
                                    de “Maestro Iberoamericano del Diagnóstico y Tratamiento Prenatal” por la Sociedad Iberoamericana (SIADTP) en la ciudad de Guatemala.</p>
                    <p>En Junio 2019 obtiene el premio Alejandrina (primer lugar en la Modalidad de Joven Talento a la Investigación) que otorga la
                        Universidad Autónoma de Querétaro.</p>
                </div>
            </main>
        </div>
    );
}

export default RogelioCruz;
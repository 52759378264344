import logo from './logo.svg';
import './styles/Header.css';
import React, { useEffect, useState, useRef } from 'react'



function Header() {

  const [hamb, setHamb] = useState(false);

  useEffect(() => {

    if (window.screen.width <= 600) {
      setHamb(true);
    }
    else {
      setHamb(false);
    }
  }, [window.screen.width])
  return (
    <>
      {hamb ? (
        <>
          <nav role="navigation">
            <div id="menuToggle">
              <input type="checkbox" />
              <span></span>
              <span></span>
              <span></span>
              <ul id="menu">
                <a href="/" className="logo">
                  <img src={process.env.PUBLIC_URL + "/img/fundacionlogo.png"} style={{ width: "100%" }} />
                </a>
                <a href="/" ><li>Inicio</li></a>
                <li><div>
                  <div className="dropdown">
                    <button className="dropbtn">Acerca de</button>
                    <div className="dropdown-content">
                      <a href="/knowus">La Fundación</a>
                      <a href="/education">Educación</a>
                      <a href="/investigation">Investigación</a>
                      <a href="/socialwork">Labor Social</a>
                      <a href="/neurodevelopment">Neurodesarrollo</a>
                      <a href="/productivity">Productividad</a>
                    </div>
                  </div>
                </div></li>
                <li></li>
                <a href="/fetoton"><li>FETOTON</li></a>
                <a href="/mypregnancy"><li>Mi embarazo</li></a>
                <a href="/events"><li>Eventos</li></a>
                <a href="/news"><li>Prensa</li></a>
                <a href="/forum"><li>Foro</li></a>
                <a href="/testimony"><li>Testimonio</li></a>
                <a href="/support"><li>Cómo Apoyar</li></a>
              </ul>
            </div>
          </nav>
        </>
      ) : (
        <>
          <header>
            <a href="/" className="logo">
              <img src={process.env.PUBLIC_URL + "/img/fundacionlogo.png"} style={{ width: "100%", maxWidth: "200px" }} />
            </a>
            <div className="menu">
              <a href="/" className="menu-link">Inicio</a>
              <div className='menu-link'>
                <div className="dropdown">
                  <button className="dropbtn">Acerca de
                    <i className="fa fa-caret-down"></i>
                  </button>
                  <div className="dropdown-content">
                    <a href="/knowus">La Fundación</a>
                    <a href="/education">Educación</a>
                    <a href="/investigation">Investigación</a>
                    <a href="/socialwork">Labor Social</a>
                    <a href="/neurodevelopment">Neurodesarrollo</a>
                    <a href="/productivity">Productividad</a>
                  </div>
                </div>
              </div>
              <a href="/fetoton" className="menu-link">FETOTON</a>
              <a href="/mypregnancy" className="menu-link">Mi Embarazo</a>
              <a href="/events" className="menu-link">Eventos</a>
              <a href="/news" className="menu-link">Prensa</a>
              <a href="/forum" className="menu-link">Foro</a>
              <a href="/testimony" className="menu-link">Testimonios</a>
              <a href="/support" className="menu-link">Cómo Apoyar</a>
            </div>
          </header>
        </>
      )}
    </>
  );
}

export default Header;
import React from 'react';
import "./styles/SocialWork.css"

function SocialWork() {
    return (
        <div className="site-content">
            <main>
                <div className="content-body social-labor-page">
                    <div className="section-title">
                        <h2>Labor Social</h2>
                        <hr />
                    </div>
                    <div className="hero-image">
                        <img src={process.env.PUBLIC_URL + "/img/fetoton20212.png"} />
                    </div>
                    <div>
                        <p>
                            Hacemos la invitación a este evento a nivel nacional, donde acudiremos a comunidades de difícil acceso a servicios de salud tanto de centros
                            como de hospitales con la finalidad de brindar atención a mujeres embarazadas de cualquier edad gestacional realizando ultrasonidos avanzados,
                            también ofreceremos consulta médica y genética ante cualquier diagnóstico de patología, con la finalidad de reducir preeclampsia temprana,
                            prematuridad y morbilidad neonatal.
                            La primera brigada se realizará este 15 de Mayo en Ciudad Fernández San Luis Potosí, contando con la participación de Médicos Especialistas
                            Materno Fetal con mayor prestigio en el país, además de contar con  equipos de ultrasonido de última generación.
                        </p>
                    </div>
                    <div>
                        <p>
                            En nuestra fundación ofrecemos ultrasonidos avanzados Gratuitos para que todas las mujeres embarazadas tengan la oportunidad de ver a
                            su bebé en una alta resolución y que puedan tener un embarazo tranquilo y saludable.
                        </p>
                    </div>
                    <br />
                    <div className="section-title">
                        <h2>Lo que ofrecemos</h2>
                        <hr />
                    </div>
                    <h2 className="main-header">
                        <hr />
                        <div>11-14 semanas de gestacion</div>
                    </h2>
                    <div className="offer-container">
                        <div className="offer">
                            <div className="image">
                                <img src={process.env.PUBLIC_URL + "/img/imagen1.jpg"} style={{ width: "100%" }} />
                            </div>
                            <div className="text-cover">
                                <p>Descarta malformaciones físicas.</p>
                            </div>
                            <div className="title">Ecografía estructural o anatómica de primer trimestre</div>
                        </div>
                        <div className="offer">
                            <img src={process.env.PUBLIC_URL + "/img/imagen2.jpg"} style={{ width: "100%" }} />
                            <div className="text-cover">
                                <p>
                                    Identifica la población en riesgo de presentar una alteración genética como el Síndrome de Down.
                                </p>
                            </div>
                            <div className="title">Tamizaje de anomalías cromosómicas</div>
                        </div>
                        <div className="offer">
                            <img src={process.env.PUBLIC_URL + "/img/imagen3.jpg"} style={{ width: "100%" }} />
                            <div className="text-cover">
                                <p>
                                    Identifica a las madres con riesgo de desarrollar preclamsia durante el transcurso de su embarazo.
                                </p>
                            </div>
                            <div className="title">Predicción de preeclampsia</div>
                        </div>
                        <div className="offer">
                            <img src={process.env.PUBLIC_URL + "/img/imagen4.jpg"} />
                            <div className="text-cover">
                                <p>
                                    Detecta a los bebés que pueden presentar una cardiopatía antes de que esta sea evidente por ultrasonido.
                                </p>
                            </div>
                            <div className="title">Predicción del desarrollo de enfermedades cardiacas fetales</div>
                        </div>
                    </div>
                    <h2 className="main-header">
                        <hr />
                        <div>20 semanas de gestación</div>
                    </h2>
                    <div className="offer-container">
                        <div className="offer">
                            <img src={process.env.PUBLIC_URL + "/img/imagen5.jpg"} />
                            <div className="text-cover">
                                <p>
                                    Para detectar o descartar malformaciones físicas en los diferentes órganos fetales.
                                </p>
                            </div>
                            <div className="title">Evaluación Estructural o Anatómica</div>
                        </div>
                        <div className="offer">
                            <img src={process.env.PUBLIC_URL + "/img/imagen6.jpg"} />
                            <div className="text-cover">
                                <p>
                                    Para predicción de prematuridad (Identifica a las madres en riesgo de presentar un parto prematuro e iniciar tratamientos preventivos).
                                </p>
                            </div>
                            <div className="title"> Medición de la longitud cervical</div>
                        </div>
                    </div>
                    <h2 className="main-header">
                        <hr />
                        <div>Tercer trimestre</div>
                    </h2>
                    <div className="offer-container">
                        <div className="offer">
                            <img src={process.env.PUBLIC_URL + "/img/imagen7.jpg"} />
                            <div className="text-cover">
                            </div>
                            <div className="title">
                                Evaluación del crecimiento y circulación sanguínea fetal para identificar a los bebés con problemas de crecimiento y evitar la muerte o daño
                                cerebral.
                            </div>
                        </div>
                        <div className="offer">
                            <img src={process.env.PUBLIC_URL + "/img/imagen8.png"} />
                            <div className="text-cover">
                            </div>
                            <div className="title"> Evaluación del desarrollo cerebral y función cardiaca</div>
                        </div>
                        <div className="offer">
                            <img src={process.env.PUBLIC_URL + "/img/imagen9.jpg"} />
                            <div className="text-cover">
                                <p>
                                    Detecta a los bebés que presentan aún inmadurez fetal y permite programar su nacimiento en el momento oportuno.
                                </p>
                            </div>
                            <div className="title">Evaluación de la madurez pulmonar fetal</div>
                        </div><div className="offer">
                            <img src={process.env.PUBLIC_URL + "/img/imagen10.jpg"} />
                            <div className="text-cover">
                                <p>
                                    Para identificar a las madres cuyos bebés no tolerarán el trabajo de parto.
                                </p>
                            </div>
                            <div className="title"> Predicción de riesgo de pérdida de bienestar fetal durante el trabajo de parto</div>
                        </div>
                        <div className="offer">
                                <img src={process.env.PUBLIC_URL + "/img/imagen11.png"} />
                            <div className="text-cover">
                                <p>
                                    Para identificar en vida fetal a los bebés en riesgo de presentar alguna alteración en su conducta, lenguaje o
                                    aprendizaje posterior al nacimiento.
                                </p>
                            </div>
                            <div className="title">Predicción del desarrollo neurológico infantil</div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default SocialWork;
import { useEffect } from 'react';
import "./styles/Events.css"

function Events() {
    return (
        <>
            <div className="content-body events-page">
                <div className="section-title">
                    <h2>Próximos eventos</h2>
                    <hr />
                    <div className="event-card">
                        <div className="circle-up">
                        </div>
                        <div className="box">
                            <h3></h3>
                            <div className="line">
                                <img src={process.env.PUBLIC_URL + "/img/calendar.svg"} style={{ width: "100%", maxWidth: "15px" }} />
                                <h4>Marzo 2023</h4>
                            </div>
                            <div className="line">
                                <img src={process.env.PUBLIC_URL + "/img/location.svg"} style={{ width: "100%", maxWidth: "15px" }} />
                                <h4>OXXO</h4>
                            </div>
                            <p>
                                De enero a marzo de 2023 cada redondeo que hagas en tiendas Oxxo de Zapopan ayudarás con la compra de materiales e insumos médicos
                                necesarios en las cirugías fetales y terapias neonatales. Tu aportación ayuda a mamás y bebés en vida fetal
                            </p>
                            <div className="card-img">
                                <img src={process.env.PUBLIC_URL + "/img/oxxo.jpeg"} style={{ width: "100%" }} />
                            </div>
                        </div>
                        <div className="circle-bot"></div>
                    </div>
                    <div className="event-card">
                        <div className="circle-up">
                        </div>
                        <div className="box">
                            <h3></h3>
                            <div className="line">
                                <img src={process.env.PUBLIC_URL + "/img/calendar.svg"} style={{ width: "100%", maxWidth: "15px" }} />
                                <h4>Febrero 2023</h4>
                            </div>
                            <div className="line">
                                <img src={process.env.PUBLIC_URL + "/img/location.svg"} style={{ width: "100%", maxWidth: "15px" }} />
                                <h4>Fundación Medicina Fetal</h4>
                            </div>
                            <p>
                                En conmemoración del día mundial de las cardiopatías congénitas la Fundación Medicina Fetal México te invita a escuchar el
                                corazón de tu bebe. Durante todo el mes de febrero agenda tu cita y escucha el #CORAZÓNDETUBEBÉ Nuestra misión es hacer que disfrutes
                                con tranquilidad el milagro de dar vida
                            </p>
                            <div className="card-img">
                                <img src={process.env.PUBLIC_URL + "/img/cardiopatia.jpeg"} style={{ width: "100%" }} />
                            </div>
                        </div>
                        <div className="circle-bot"></div>
                    </div>

                    <div className="event-card">
                        <div className="circle-up">
                        </div>
                        <div className="box">
                            <h3></h3>
                            <div className="line">
                                <img src={process.env.PUBLIC_URL + "/img/calendar.svg"} style={{ width: "100%", maxWidth: "15px" }} />
                                <h4>26 enero 2023</h4>
                            </div>
                            <div className="line">
                                <img src={process.env.PUBLIC_URL + "/img/location.svg"} style={{ width: "100%", maxWidth: "15px" }} />
                                <h4>Krystal Grand Nuevo Vallarta, Nuevo Vallarta, Nayarit</h4>
                            </div>
                            <p>
                                Agenda tu cita 442 168 7879
                            </p>
                            <div className="card-img">
                                <img src={process.env.PUBLIC_URL + "/img/Congreso.jpg"} style={{ width: "100%" }} />
                            </div>
                        </div>
                        <div className="circle-bot"></div>
                    </div>



                </div>
                <div className="event-card">
                    <div className="circle-up">
                    </div>
                    <div className="box">
                        <h3>Diplomado - Diagnóstico e Intervención Temprana en el Neurodesarrollo</h3>
                        <div className="line">
                            <img src={process.env.PUBLIC_URL + "/img/calendar.svg"} style={{ width: "100%", maxWidth: "15px" }} />
                            <h4>Enero 2023</h4>
                        </div>
                        <div className="line">
                            <img src={process.env.PUBLIC_URL + "/img/location.svg"} style={{ width: "100%", maxWidth: "15px" }} />
                            <h4>Fundación Medicina Fetal </h4>
                        </div>
                        <ul>
                            <li>
                                <h2>
                                    <b>OBJETIVO DEL DIPLOMADO</b>
                                </h2>
                            </li>
                        </ul>
                        <p>
                            Uno de los objetivos primordiales es ofrecer a los especialistas afines al área de neurodesarrollo, conocimiento desde los principios
                            científicos, mediante un marco teórico y práctico, sobre el abordaje, la intervención, el diagnóstico y pronóstico de las alteraciones
                            de neurodesarrollo; Con la finalidad de realizar un abordaje multidisciplinario que favorezca y permita alcanzar un óptimo desarrollo en
                            población infantil expuesto a factores de riesgo.
                        </p>
                        <ul>
                            <li>
                                <h2>
                                    <b>INDICADOR DE IMPACTO</b>
                                </h2>
                            </li>
                        </ul>
                        <p>Disminución de morbilidad y de las alteraciones del desarrollo perinatal, neonatal e infantil.</p>
                        <ul>
                            <li>
                                <h2>
                                    <b>COMPETENCIAS A DESARROLLAR</b>
                                </h2>
                            </li>
                        </ul>
                        <p>
                            Al finalizar el diplomado, los alumnos deberán haber afianzado los conceptos esenciales para el diagnóstico de las alteraciones de
                            Neurodesarrollo reconociendo puntos clave para establecer un pronóstico adecuado, comprender los procesos fisiopatológicos de cada patología,
                            reconocer los criterios y proceso de selección para los diferentes tipos de abordaje terapéuticos y deberán haber completado una curva
                            de aprendizaje.

                            Se convertirá a cada alumno en un profesional altamente capacitado para desempeñarse de manera ética y eficiente en el diagnóstico y manejo
                            de las patologías susceptibles de tratamiento.
                        </p>
                        <ul>
                            <li>
                                <h2>
                                    <u>
                                        <b>Fecha de inicio:</b>
                                    </u>
                                </h2>
                            </li>
                        </ul>
                        <p>
                            Sesión online lunes 10 de enero 2022 (abre el primer módulo virtual)
                            Sesión presencial viernes 28 de enero de 2022 (primer módulo)
                            Clausura:
                            Viernes 25 de junio de 2022
                            Cupo limitado a 25 alumnos
                            Las postulaciones de los candidatos serán a través de la página web
                            (www.fundacionmfm.com.mx)
                        </p>
                        <ul>
                            <li>
                                <h2>
                                    <u>
                                        <b>Dirigido a:</b>
                                    </u>
                                </h2>
                            </li>
                        </ul>
                        <p>
                            Licenciatura en rehabilitación, fisioterapeutas, educación especial, terapia ocupacional, terapeutas de lenguaje, logopedia, pedagogía,
                            enfermería pediátrica, profesionales afines al área de neurodesarrollo o su homólogo de especialidad de acuerdo con el país

                            Los interesados deberán realizar su postulación durante el periodo Agosto 2021 a Noviembre 2021.
                            Diciembre 2021 envío de cartas de aceptación
                        </p>
                        <p>
                            Las postulaciones de los candidatos serán a través de esta página en la cual podrán llenar su formulario y recibir una respuesta
                            <a href="https://forms.gle/9KLZ7A5AacSs4kNJ9">https://forms.gle/9KLZ7A5AacSs4kNJ9</a></p><p></p><div className="card-img">
                            <img src={process.env.PUBLIC_URL + "/img/img1.jpg"} style={{ width: "100%" }} />
                        </div>
                    </div>
                    <div className="circle-bot"></div>
                </div>
                <div className="event-card">
                    <div className="circle-up"></div><div className="box">
                        <h3>Fetoton 2021</h3>
                        <div className="line">
                            <img src={process.env.PUBLIC_URL + "/img/calendar.svg"} style={{ width: "100%", maxWidth: "15px" }} />
                            <h4>15 de Mayo de 2021</h4>
                        </div>
                        <div className="line">
                            <img src={process.env.PUBLIC_URL + "/img/location.svg"} style={{ width: "100%", maxWidth: "15px" }} />
                            <h4>Ciudad Fernández, San Luis Potosí, México.</h4>
                        </div>
                        <p>
                            Durante este evento acudimos a comunidades de difícil acceso a centros de salud o a hospitales para brindar atención a mujeres
                            embarazadas de cualquier edad gestacional realizando ultrasonidos avanzados, también ofrecemos consulta médica y genética ante cualquier
                            diagnóstico de patología, con la finalidad de reducir preeclampsia temprana, prematuridad y morbilidad neonatal.
                        </p>
                        <div className="card-img">
                            <img />
                        </div>
                    </div>
                    <div className="circle-bot"></div>
                </div>
                <div className="event-card">
                    <div className="circle-up"></div><div className="box">
                        <h3>A celebrar a mamá</h3>
                        <div className="line">
                            <img src={process.env.PUBLIC_URL + "/img/calendar.svg"} style={{ width: "100%", maxWidth: "15px" }} />
                            <h4>10 de Mayo de 2020</h4>
                        </div>
                        <div className="line">
                            <img src={process.env.PUBLIC_URL + "/img/location.svg"} style={{ width: "100%", maxWidth: "15px" }} />
                            <h4>México</h4>
                        </div>
                        <p>
                            Evento que realizamos el 10 de Mayo como celebración al día de las madres en México, invitamos a todas las mamás de la ciudad mediante
                            medios de comunicación para que asistan al evento, ofrecemos música, comida y baile, al final cerramos con un agradecimiento especial a todas
                            y cada una de las mamás por su donativo y por el esfuerzo que realizan día a día por sacar adelante a sus hijos y familia. A todas las mamás
                            trabajadoras, dentro o fuera de casa, les agradecemos su donativo.
                        </p>
                        <div className="card-img">
                            <img />
                        </div>
                    </div>
                    <div className="circle-bot"></div>
                </div>
                <div className="event-card">
                    <div className="circle-up"></div><div className="box">
                        <h3>Carrera con causa</h3>
                        <div className="line">
                            <img src={process.env.PUBLIC_URL + "/img/calendar.svg"} style={{ width: "100%", maxWidth: "15px" }} />
                            <h4>2020</h4>
                        </div>
                        <div className="line">
                            <img src={process.env.PUBLIC_URL + "/img/location.svg"} style={{ width: "100%", maxWidth: "15px" }} />
                            <h4>México</h4>
                        </div>
                        <p>
                            Durante este pequeño maratón invitamos al público en general que asistan con su familia a recorrer pocos kilómetros en beneficio de nuestros
                            bebés sometidos a cirugía, cada inscripción cuenta como apoyo para insumos, material y medicamentos para el programa de cirugía fetal.
                        </p>
                        <div className="card-img">
                            <img />
                        </div>
                    </div>
                    <div className="circle-bot"></div>
                </div>
                <div className="event-card">
                    <div className="circle-up"></div><div className="box">
                        <h3>Reunión Anual de Espina Bífida</h3>
                        <div className="line">
                            <img src={process.env.PUBLIC_URL + "/img/calendar.svg"} style={{ width: "100%", maxWidth: "15px" }} />
                            <h4>2020</h4>
                        </div>
                        <div className="line">
                            <img src={process.env.PUBLIC_URL + "/img/location.svg"} style={{ width: "100%", maxWidth: "15px" }} />
                            <h4>México</h4>
                        </div>
                        <p>
                            Con gusto conmemoramos esta fecha reuniendo a todos los papás y a sus bebés que fueron intervenidos intraútero para corrección de espina bífida. 
                            Durante un ameno convivio en el área de neurodesarrollo brindamos una plática para padres acentuando la importancia de su colaboración y el 
                            agradecimiento de nuestro equipo por su compromiso y tenacidad. El objetivo es que compartan experiencias y vean juntos la evolución de sus hijos 
                            con cada terapia, con cada día.
                        </p>
                        <div className="card-img">
                            <img />
                        </div>
                    </div>
                    <div className="circle-bot"></div>
                </div>
            </div>
        </>
    );
}

export default Events;
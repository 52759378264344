import './styles/Footer.css'

function Footer() {
    return (
        <footer className="footer column">
            <div className="footer-content content-screen">
                <div className="main-content">
                    <div className="footer-links" style={{ flex: "1 1 0%", justifyContent: "space-between" }}>
                        <div className="column">
                            <h4>Enlaces</h4>
                            <a aria-current="page" className="link" href="/">Inicio</a>
                            <a className="link" href="/mypregnancy">Mi Embarazo</a>
                            <a className="link" href="/events">Eventos</a>
                            <a className="link" href="/forum">Foro</a>
                            <a className="link" href="/support">Cómo apoyar</a>
                        </div>
                        <div className="column">
                            <h4>Conócenos</h4>
                            <a className="link" href="/fetoton">FETOTON</a>
                            <a className="link" href="/investigation">Investigación</a>
                        </div>
                        <div className="column">
                            <h4>Enlaces</h4>
                            <a className="link" href="https://medicinafetalmexico.com">Fundacion MFM</a>
                        </div>
                    </div>
                </div>
                <div className="social-links row">
                    <div className="social-link icon white">
                        <a href="https://www.facebook.com/fundacionmedfetalmexico">
                            <img src={process.env.PUBLIC_URL + "/img/facebook.png"} style={{ maxWidth: "12px" }} />
                        </a>
                    </div>
                    <div className="social-link icon white">
                        <a href="https://www.instagram.com/fundacionmedicinafetalmexico/">
                            <img src={process.env.PUBLIC_URL + "/img/instagram.png"} style={{ maxWidth: "20px" }} />
                        </a>
                    </div>
                </div>
                <hr />
                <div className="row footer-bottom">
                    <p>Copyright © 2019. Derechos Reservados </p>
                    <a className="link" href="/privacy"> aviso de privacidad </a>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
import { useEffect } from 'react';
import "./styles/Education.css"

function Education() {
    return (
        <>
            <div className="site-content">
                <main>
                    <div className="content-body about-page">
                        <div>
                            <div className="section-title">
                                <h2>Educación</h2>
                                <hr />
                                <img src={process.env.PUBLIC_URL + "/img/education.jpg"} style={{ width: "100%" }} />
                            </div>
                            <p>
                                Estamos orgullosos de todos los médicos mexicanos y extranjeros que desean seguir aprendiendo para mejoras la atención con sus pacientes,
                                nuestro compromiso es con ellos, por lo tanto, les ofrecemos becas para que puedan asistir a los cursos realizados por el equipo de Medicina
                                Fetal México.
                            </p>
                            <p>
                                El día que se publiquen las fechas de los cursos, es el momento para solicitar tu beca.
                            </p>
                            <p>
                                Aprende de la mano de expertos.
                            </p>

                            <div className="section-title">
                                <h2>Oferta Educativa 2020</h2>
                                <hr />
                                <p>
                                    Esta es nuestra oferta de cursos, talleres y posgrados:
                                </p>
                            </div>
                            <ul>
                                <li>
                                    Curso práctico de entrenamiento en amniocentesis y biopsia de vellosidades coriales
                                    <p>
                                        Querétaro, 6 y 7 Diciembre 2019
                                    </p>
                                </li>
                                <li>
                                    Curso básico de diagnóstico prenatal en primer trimestre
                                    <p>
                                        Querétaro, 18 y 19 Enero 2020
                                    </p>
                                </li>
                                <li>
                                    Taller teórico práctico de Doppler en medicina materno fetal
                                    <p>
                                        Querétaro, 6 y 7 Marzo 2020
                                    </p>
                                </li>
                                <li>
                                    Cursos básico y avanzado de ecocardiografía fetal
                                    <p>
                                        Querétaro, 22 y 23 Mayo 2020
                                    </p>
                                </li>
                                <li>
                                    Curso de Alta Especialidad en cirugía fetal
                                    <p>
                                        Querétaro, Enero - Diciembre 2020
                                    </p>
                                </li>
                                <li>
                                    Posgrado en Medicina Fetal
                                    <p>
                                        Querétaro, 2020, 2021
                                    </p>
                                </li>
                            </ul>
                            <p>
                            </p>
                            <div style={{display: "flex", gap: "15px", flexWrap: "wrap"}}>
                                <img src={process.env.PUBLIC_URL + "/img/education3.jpeg"} style={{ width: "100%", maxWidth: "350px" }} />
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}

export default Education;
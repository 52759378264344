import React from 'react';
import "./styles/CarWash.css"
import { Carousel } from 'react-responsive-carousel';
import "animate.css/animate.compat.css"
import ScrollAnimation from 'react-animate-on-scroll';

function CarWash() {
    return (
        <div className='cw-page'>
            <br /><br /><br />
            <div ><img src={process.env.PUBLIC_URL + "/img/cw1.png"} /></div>
            <ScrollAnimation animateIn="heartBeat" animateOut='fadeOut' offset={150} animatePreScroll={true}>
                <div ><img src={process.env.PUBLIC_URL + "/img/cw2.png"} /></div>
            </ScrollAnimation>
            <ScrollAnimation animateIn="bounceInLeft" animateOut='fadeOut' offset={150} animatePreScroll={true}>
                <div ><img src={process.env.PUBLIC_URL + "/img/cw3.png"} /></div>
            </ScrollAnimation>
            <ScrollAnimation animateIn="zoomIn" animateOut='fadeOut' offset={150} animatePreScroll={true}>
                <div ><img src={process.env.PUBLIC_URL + "/img/cw4.png"} /></div>
            </ScrollAnimation>
            <ScrollAnimation animateIn="zoomInUp" animateOut='fadeOut' offset={150} animatePreScroll={true}>
                <div ><img src={process.env.PUBLIC_URL + "/img/cw5.png"} /></div>
            </ScrollAnimation>
            <ScrollAnimation animateIn="slideInRight" animateOut='fadeOut' offset={150} animatePreScroll={true}>
                <div ><img src={process.env.PUBLIC_URL + "/img/cw6.png"} /></div>
            </ScrollAnimation>
        </div>
    );
}

export default CarWash;
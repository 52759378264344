import { useEffect } from 'react';
import "./styles/Book.css"
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

function Book() {

    return (
        <>
            <div className="content-body support-page">
                <div className="section-title">
                    <h2>Comprar libro</h2>
                    <hr />
                    <div className="support-card" style={{ width: "100%" }}>
                        <div>
                            <p>
                                Opción 1</p>
                        </div>
                        <div className="donations-card" style={{ display: "flex", justifyContent: "center" }}>
                            <div className="circle-up"></div>
                            <div className="circle-bot"></div>
                            <PayPalScriptProvider options={{ clientId: 'AapUGGBGoyNBt1cGA3gVnCR3_vTvfUhwHpuz1CF3XW_VWuL_u-WMYxemrpITpaknMMtz39cWEskwo35L', currency: 'MXN', vault: 'true' }} >
                                <PayPalButtons style={{ 'color': 'blue', }}
                                    className="button-paypal"
                                    createOrder={(data, actions) => {
                                        return actions.order.create({
                                            'purchase_units': [{
                                                'amount': {
                                                    value: "2000"
                                                }
                                            }],
                                            "application_context": { "brand_name": "Fundación Medicina Fetal México" }
                                        })
                                    }}
                                >
                                </PayPalButtons>
                            </PayPalScriptProvider>
                        </div>
                    </div>
                    <div className="line-1"><span className="line-2">o</span></div>
                    <br /><br /><br />
                </div>
                <div className="support-card">
                    <div>
                        <p>
                            Opción 2</p>
                    </div>
                    <div className="donations-card">
                        <div className="circle-up"></div>
                        <div className="circle-bot"></div>
                        <p>
                            <b>
                                Nombre de la fundación:
                            </b>
                            Fundación Medicina Fetal México A.C
                        </p>
                        <p>
                            <b>
                                Banco:
                            </b>
                            BANORTE
                        </p>
                        <p>
                            <b>
                                No. Cuenta:
                            </b>
                            1219889884
                        </p>
                        <p>
                            <b>
                                CLABE:
                            </b>
                            072 320 012198898846
                        </p>
                    </div>
                    <p>
                        ¡Contáctanos! Escribe un mail a
                        <a className="secondary-link" href="mailto:donacionesfundacion@medicinafetalmexico.com?Subject=Nueva%20Pregunta">donacionesfundacion@medicinafetalmexico.com</a>
                        o llámanos al 4421687879.
                    </p>
                </div>
                <div className="row button-container">
                    <a className="button button-cta" href="mailto:donacionesfundacion@medicinafetalmexico.com?Subject=Nueva%20Pregunta">
                        Enviar Correo Electrónico
                    </a>
                    <a className="button button-cta" href="tel:4421957014">
                        Llamar vía Teléfono
                    </a>
                </div>
                <br />
                <p>
                    No olvides de seguirnos en nuestras redes sociales:
                </p>
                <div className="social-media-container">
                    <a href="https://www.facebook.com/fundacionmedfetalmexico" />
                    <img />
                    <img />
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "15px" }}>
                    <div className="social-media-container">
                        <a href="https://www.facebook.com/fundacionmedfetalmexico">
                            <img src={process.env.PUBLIC_URL + "/img/facebook.png"} style={{ maxWidth: "20px" }} />
                        </a>
                    </div>
                    <div className="social-media-container">
                        <a href="https://www.instagram.com/fundacionmedicinafetalmexico/">
                            <img src={process.env.PUBLIC_URL + "/img/instagram.png"} style={{ maxWidth: "30px" }} />
                        </a>
                    </div>
                </div>
                <br /><br /><br />
                <div className="section-title"><h2>Nuestros Patrocinadores</h2><hr /></div>
                <div className="images-container">
                    <img src={process.env.PUBLIC_URL + "/img/dif.png"} style={{ width: "100%" }} />
                    <img src={process.env.PUBLIC_URL + "/img/fetalmed.png"} style={{ width: "100%" }} />
                    <img src={process.env.PUBLIC_URL + "/img/premiumSystem.jpeg"} style={{ width: "100%" }} />
                    <img src={process.env.PUBLIC_URL + "/img/kimberly.jpeg"} style={{ width: "100%" }} />
                    <img src={process.env.PUBLIC_URL + "/img/defestatal.jpeg"} style={{ width: "100%" }} />
                    <img src={process.env.PUBLIC_URL + "/img/cemefi.png"} style={{ width: "100%" }} />
                    <img src={process.env.PUBLIC_URL + "/img/OXO.png"} style={{ width: "100%" }} />
                </div>
            </div>
        </>
    );
}

export default Book;
import { useEffect } from 'react';
import "./styles/Knowus.css"

function Knowus() {
    return (
        <>
            <div className="site-content">
                <main>
                    <div className="content-body about-page">
                        <div>
                            <div className="section-title">
                                <h2>Conócenos</h2>
                                <hr />
                            </div>
                            <p>
                                Somos una fundación sin fines de lucro que cuenta con tres objetos sociales: educación médica contínua, investigación, labor social.
                            </p>
                            <p>
                                Proporcionamos atención integral avanzada para el cuidado de la salud y seguridad tanto de la madre como del bebé en vida fetal,
                                por medio de la última tecnología y de nuestro personal altamente calificado.
                            </p>
                            <p>
                                Esta fundación se ha creado con la finalidad de facilitar el acceso al área de la educación otorgando becas a médicos que aún están en
                                formación para que puedan asistir a cursos avanzados de alta especialidad, facilitar también, la información materna, siempre bajo el lema
                                “información en salud” lo que nos ha llevado a crear sesiones con madres para informarles sobre mitos y realidades durante el embarazo y así
                                tengan más apego al seguimiento médico, esto de la mano con realizar ecografías gratuitas a mujeres de escasos recursos para predecir y
                                prevenir multiples patologías y con ello disminuir la morbimortalidad materna e infantil en nuestro México.
                            </p>
                            <div className="section-title">
                                <h2>¿Quiénes somos?</h2>
                                <hr />
                            </div>
                            <p>
                                Somos un grupo de profesionales de la salud, médicos especialistas, investigadores, rotantes nacionales e internacionales, personal de
                                enfermería y tanatología, todos con el mismo objetivo, enfocar nuestro esfuerzo a programas de alta especialidad, atención médica
                                preventiva a comunidad aledaña, investigación y enseñanza.
                            </p>
                            <p>
                                Nuestro compromiso es brindar atención de alta calidad y de vanguardia a todas las mujeres embarazadas económicamente vulnerables
                                para que las futuras generaciones de mexicanos se desarrollen saludablemente y lleguen a su máximo potencial.
                            </p>
                            <p>
                                Ofrecemos servicios de salud con los más altos estándares de atención para mejorar la calidad de vida de nuestras pacientes,
                                sus bebés y su familia.
                            </p>
                            <p>
                                Contamos en algunos de nuestros programas con altruismo médico, la actividad más noble que puede llevar a cabo un médico,
                                dando su conocimiento, habilidades y tiempo sin recibir remuneración a cambio. Esta actividad refleja los valores del equipo
                                y su actitud de servicio. La atención altruista inició junto con la fundación y hoy se manifiesta de diversas formas.
                            </p>
                            <div className="section-title">
                                <h2>Misión</h2>
                                <hr />
                            </div>
                            <p>
                                Nuestra misión es conservar la salud materna, prenatal, neonatal e infantil.
                            </p>
                            <p>
                            </p>
                            <div className="section-title">
                                <h2>Visión</h2>
                                <hr />
                            </div>
                            <p>
                                En Fundación Medicina Fetal México somos una organización de referencia en el ámbito del Diagnóstico Prenatal, Terapia Fetal y
                                Neurodesarrollo postnatal, que cubra todas las necesidades de manera integral para las mujeres embarazadas, basada en la eficacia
                                y calidad de servicio de nuestros especialistas.
                            </p>
                            <div className="section-title">
                                <h2>¿Qué ofrecemos?</h2>
                                <hr />
                            </div>
                            <ul>
                                <li>
                                    <p>
                                        Tenemos como principal objetivo disminuir la morbilidad y mortalidad neonatal y materna en nuestro país realizando
                                        ultrasonidos fetales avanzados, estudios invasivos y cirugías fetales de manera gratuita para la población de escasos
                                        recursos.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Contamos con un área de Neurodesarrollo infantil, en el cual ofrecemos a todos los bebés, hayan sido sometidos a cirugía fetal o no,
                                        estimulación oportuna, neurohabilitación o rehabilitación, según el bebé lo requiera.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Ofrecemos apoyo psicológico y terapia a las familias que han tenido alguna pérdida cercana y se encuentren en proceso de duelo,
                                        mediante nuestro grupo de expertos en tanatología.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Apoyamos la investigación mediante nuestros médicos investigadores de nivel internacional miembros de Conacyt
                                        SNIIII, produciendo innovaciones a la medicina fetal.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Otorgamos becas para la formación de nuevos profesionales.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Ofrecemos, mediante nuestro sitio web, información útil para todas las mujeres embarazadas, sobre su salud,
                                        la salud del bebé y su entorno.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Promovemos la participación de las embarazadas y su familia mediante nuestro foro de preguntas y respuestas, la cual tiene como
                                        único objetivo informar, nunca reemplazar la consulta médica.
                                    </p>
                                </li>
                            </ul>
                            <p>
                            </p>
                        </div>
                        <div className="column aifs">
                            <img src={process.env.PUBLIC_URL + "/img/fundacionlogo.png"} style={{ width: "100%", maxWidth: "200px" }} />
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}

export default Knowus;
import { createContext, useContext, useState } from "react";

type props = {
    children: string | JSX.Element | React.ReactNode
}

type OverlayType = {
    display: string,
    content: string | JSX.Element | React.ReactNode
}

type OverlayContextType = [OverlayType, React.Dispatch<React.SetStateAction<OverlayType>>]

const overlayContext = createContext<OverlayContextType>([{
    display: "none",
    content: <></>
}, () => null]);

export const useOverlay = () => {
    return useContext(overlayContext);
}

function OverlayProvider(props: props) {
    const [overlay, setOverlay] = useState<OverlayType>({
        display: "none",
        content: <></>
    });
    return (
        <overlayContext.Provider value={[overlay, setOverlay]}>
            {props.children}
        </overlayContext.Provider>
    );
}

export default OverlayProvider;
import { useEffect } from 'react';
import "./styles/Fetoton.css"

function Fetoton() {
    return (
        <div className="content-body compartim-page">
            <div className="section-title">
                <h2>FETOTON 2023</h2>
                <hr />
            </div>
            <div className="event-card">
                <div className="circle-up"></div>
                <div className="box">
                    <h3></h3>
                    <video src="https://s3.amazonaws.com/fundacionmfm.com/fetoton-video2.mp4" style={{width: "100%"}} autoPlay muted loop controls/>
                    <div className="card-img">
                        <br />
                    </div>
                </div>
                <div className="circle-bot"></div>
            </div>
            <div className="event-card">
                <div className="circle-up"></div>
                <div className="box">
                    <h3></h3>
                    <video src="https://s3.amazonaws.com/fundacionmfm.com/fetoton-video.mp4" style={{width: "100%"}} autoPlay muted loop controls/>
                    <div className="card-img">
                        <br />
                    </div>
                </div>
                <div className="circle-bot"></div>
            </div>
            <div className="event-card">
                <div className="circle-up"></div>
                <div className="box"><h3></h3>
                    Brindar atención a mujeres embarazadas de cualquier edad gestacional realizando ultrasonidos avanzados, ofreciendo consulta médica y genética
                    ante cualquier diagnóstico de patología, con la finalidad de reducir preeclampsia temprana, prematuridad y morbilidad neonatal.
                    <div className="card-img">
                        <br />
                    </div>
                </div>
                <div className="circle-bot"></div>
            </div>
            <div className="event-card">
                <img src={process.env.PUBLIC_URL + "/img/fetoton2023.jpg"} style={{ width: "100%" }} />
                <div className="circle-bot"></div>
            </div>
            <div className="section-title">
                <h2>FETOTON 2021</h2>
                <hr />
            </div>
            <div className="event-card"><div className="circle-up"></div>
                <div className="box">
                    <h3></h3>
                    <div className="line">
                        <img src={process.env.PUBLIC_URL + "/img/calendar.svg"} style={{ width: "100%", maxWidth: "15px" }} />
                        <h4>15 de Mayo de 2021</h4>
                    </div>
                    <div className="line">
                        <img src={process.env.PUBLIC_URL + "/img/location.svg"} style={{ width: "100%", maxWidth: "15px" }} />
                        <h4>Ciudad Fernández, San Luis Potosí, México</h4>
                    </div>
                    Durante este evento acudimos a comunidades de difícil acceso a centros de salud o a hospitales para brindar atención a mujeres embarazadas de cualquier
                    edad gestacional realizando ultrasonidos avanzados, también ofrecemos consulta médica y genética ante cualquier diagnóstico de patología, con la
                    finalidad de reducir preeclampsia temprana, prematuridad y morbilidad neonatal.
                </div>
                <div className="circle-bot"></div>
            </div><div className="event-card">
                <div className="circle-up">
                </div>
                <div className="box">
                    <h3></h3>
                    <video src="https://videospruebamfm.s3.us-east-2.amazonaws.com/fetoton.mp4" style={{ width: "100%" }} autoPlay muted loop controls/>
                    <div className="card-img">
                        <br />
                    </div>
                </div>
                <div className="circle-bot"></div>
            </div>
        </div>
    );
}

export default Fetoton;
import { useEffect } from 'react';
import "./styles/MyPregnancy.css"

function MyPregnancy() {
    return (
        <div className="content-body pregnancy-page">
            <div className="section-title">
                <h2>Mi Embarazo</h2><hr />
            </div><div className="row">
                <img src={process.env.PUBLIC_URL + "/img/doctoraAlma.bmp"} style={{ width: "100%", maxWidth: "20%" }} />
                <div className="content">
                    <p>
                        La Dra. Alma Gámez presidenta de la Fundación, es columnista de la prestigiosa revista Serendipia, conoce sus artículos aquí:
                    </p>
                    <br />
                    <a href="https://www.revistaserendipia.com/ciencia/salud-prenatal/" target="_blank" className="button button-cta">Ver los artículos</a>
                </div>
            </div>
        </div>
    );
}

export default MyPregnancy;
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import { Layout } from './components/layout/Layout';
import { useEffect } from 'react';
import MyPregnancy from './pages/MyPregnancy';
import Fetoton from './pages/Fetoton';
import Event from './pages/Events';
import News from './pages/News';
import Support from './pages/Support';
import Testimony from './pages/Testimony';
import Forum from './pages/Forum';
import Knowus from './pages/Knowus';
import Education from './pages/Education';
import Investigation from './pages/Investigation';
import SocialWork from './pages/SocialWork';
import Neurodevelopment from './pages/Neurodevelopment';
import Productivity from './pages/Productivity';
import RogelioCruz from './pages/RogelioCruz';
import Privacy from './pages/Privacy';
import ComprehensivePrivacy from './pages/ComprenhensivePrivacy';
import Book from './pages/Book';
import Prueba from './pages/Prueba';
import CarWash from './pages/CarWash';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Prueba />} />
          <Route path="/mypregnancy" element={<MyPregnancy/>}/>
          <Route path="/fetoton" element={<Fetoton/>}/>
          <Route path="/events" element={<Event/>}/>
          <Route path="/news" element={<News/>}/>
          <Route path="/support" element={<Support/>}/>
          <Route path="/testimony" element={<Testimony/>}/>
          <Route path="/forum" element={<Forum/>}/>
          <Route path="/knowus" element={<Knowus/>}/>
          <Route path="/education" element={<Education/>}/>
          <Route path="/investigation" element={<Investigation/>}/>
          <Route path="/socialwork" element={<SocialWork/>}/>
          <Route path="/neurodevelopment" element={<Neurodevelopment/>}/>
          <Route path="/productivity" element={<Productivity/>}/>
          <Route path="/rogeliocruz" element={<RogelioCruz/>}/>
          <Route path="/privacy" element={<Privacy/>}/>
          <Route path="/comprehensiveprivacy" element={<ComprehensivePrivacy/>}/>
          <Route path="/book" element={<Book/>}/>
          <Route path="/prueba" element={<Prueba/>}/>
          <Route path="/carshow" element={<CarWash/>}/>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;


import React from 'react';
import "./styles/News.css"

function News() {
    return (
        <>
            <div className="content-body pregnancy-page">
                <div className="section-title">
                    <h2>Noticias nuevas</h2>
                    <hr />
                </div>
                <div className="event-card">
                    <div className="circle-up">
                    </div>
                    <div className="box">
                        <br />
                        <p style={{fontWeight: "700"}}>Entrevista Radio 89.5 La radio TV</p>
                    </div>
                    <div className="circle-bot"></div>
                </div>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <video src="https://s3.amazonaws.com/fundacionmfm.com/news2.mp4" style={{ width: "100%" }} autoPlay muted loop controls/>                    
                </div>
                <br/><br/><br/><br/>
                <div className="event-card">                    
                    <div className="circle-up"></div>
                    <div className="box"><br />
                        <p style={{fontWeight: "700"}}>Entrevista Damas de Casa 19/04/2023</p>
                    </div>
                    <div className="circle-bot"></div>
                </div>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <video src="https://s3.amazonaws.com/fundacionmfm.com/news1.mp4" style={{ width: "100%" }} autoPlay muted loop controls/>
                </div>
                <br/><br/><br/><br/>
                <div className="event-card">
                    <div className="circle-up"></div>
                    <div className="box">
                        <br />
                        <p style={{fontWeight: "700"}}>Entrevista Jalisco TV Programa Familia y Salud 24 enero 2023</p>
                    </div>
                    <div className="circle-bot"></div>
                </div>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <video src="https://videospruebamfm.s3.us-east-2.amazonaws.com/fundacionPrensa.mp4" style={{ width: "100%" }} autoPlay muted loop controls/>
                </div>
                <br /><br />
            </div>
        </>
    );
}

export default News;
import React from 'react';
import "./styles/Investigation.css"

function Investigation() {
    return (
        <div className="site-content">
            <main>
                <div className="content-body investigation-page">
                    <div className="section-title">
                        <h2>Investigación</h2>
                        <hr />
                    </div>
                    <p>
                        En Fundación Medicina Fetal México, la investigación es uno de nuestros pilares más grandes y de mayor importancia, debido a que gracias a
                        nuestra producción científica, los médicos investigadores de nuestro grupo son referentes a nivel mundial. Han creado nuevas técnicas de
                        tratamiento siendo pioneros en algunas intervenciones fetales, demostrando también su empatía con las mujeres embarazadas de nuestro país.
                    </p>
                    <p>
                        Día con día ponen todo su empeño en mejorar la medicina fetal de México y el mundo, para poder ofrecer más y mejores opciones a nuestras
                        pacientes, para que todos los niños lleguen a desarrollar su máximo potencial, por un mundo mejor.
                    </p>
                    <div className="doctors-grid">
                        <div className="doctor">
                            <div className="image-box">
                                <img src={process.env.PUBLIC_URL + "/img/Rogelio.bmp"}/>
                            </div>
                            <div className="name">
                                Dr. Rogelio Cruz Martínez, MD, PhD.
                            </div>
                            <a className="doctor-link" href="/rogeliocruz">
                                Conoce más
                            </a>
                        </div>
                    </div>
                    <div className="section-title">
                        <h2>Artículos de Investigación</h2>
                        <hr />
                    </div>
                    <div className="investigation-articles">
                        <ul>
                            <li>
                                <a href="https://www.ncbi.nlm.nih.gov/pubmed/27363854">
                                    Fetal laser ablation of feeding artery of cystic lung lesions with systemic arterial blood supply.
                                </a>
                                <p>
                                    Cruz-Martinez R, Martínez-Rodríguez M, Bermúdez-Rojas M, Magaña-Abarca C, Narváez-Domínguez V, Rojas-Macedo A, Bautista-García N,
                                    Alcocer-Alcocer M. Ultrasound Obstet Gynecol. 2017Jun; 49(6):744-750.doi:10.1002/uog16011.
                                </p>
                            </li>
                            <li>
                                <a href="https://www.ncbi.nlm.nih.gov/pubmed/29207383">
                                    Thoracic changes after full laser ablation of the feeding artery in fetuses with bronchopulmonary sequestration.
                                </a>
                                <p>
                                    Cruz-Martinez R, Nieto-Castro B, Martinez-Rodriguez M, Gámez-Varela A, Ahumada-Angulo E, Luna-García J, Pineda-Pérez  MJ,
                                    Rebolledo-Fernández C. Fetal Diagn Ther 2018, (Epub ahead of print).  doi: 10.1159/000481170.
                                </p>
                            </li>
                            <li>
                                <a href="https://www.ncbi.nlm.nih.gov/pubmed/29572873">
                                    Characterizing cardiac dysfunction in fetuses with left congenital diaphragmatic hernia.
                                </a>
                                <p>
                                    Cruz-Lemini M,
                                    <b>
                                        Cruz-Martinez R
                                    </b>,
                                    Valenzuela-Alcaraz B, Granados-Montiel J, Martínez JM, Crispi F, Gratacós E.
                                    Prenatal Diagnosis 2018 May; 38(6):422-427. doi: 10.1002.
                                </p>
                            </li>
                            <li>
                                <a href="https://www.ncbi.nlm.nih.gov/pubmed/30511776">
                                    Longitudinal changes in lung size and intrapulmonary‐artery Doppler during the second half of
                                    pregnancy in fetuses with congenital diaphragmatic hernia.
                                </a>
                                <p>
                                    Rogelio Cruz‐Martínez, Miguel Martínez‐Rodríguez, Belén Nieto‐Castro, Alma Gámez‐Varela,
                                    Mónica Cruz‐Lemini, Jonahtan Luna‐García, Israel Juárez‐Martínez. Prenatal Diagnosis.
                                    DOI: 10.1002/pd.540.
                                </p>
                            </li>
                            <li>
                                <a href="https://doi.org/10.1080/14767058.2019.1638905">
                                    Prenatal diagnosis of congenital heart defects: experience of the first Fetal
                                    Cardiology Unit in Mexico.
                                </a>
                                <p>
                                    Mónica Cruz-Lemini, Belen Nieto-Castro, Jonahtan Luna-García, Israel Juarez-Martinez,
                                    Magdalena Martínez-Rivera, Ma. de la Luz Bermudez-Rojas, Carlos Rebolledo-Fernández
                                    Rogelio Cruz-Martinez. The Journal of Maternal-Fetal Neonatal Medicine, 2019.
                                </p>
                            </li>
                            <li>
                                <a href="https://doi.org/10.1002/pd.5470">
                                    Outcomes of hypoplastic left heart syndrome and fetal aortic valvuloplasty
                                    in a country with suboptimal postnatal management.
                                </a>
                                <p>
                                    Mónica Cruz‐Lemini, Monica Alvarado‐Guaman, Belen Nieto‐Castro,
                                    Jonathan Luna‐Garcia, Miguel Martínez‐Rodríguez, Israel Juarez‐Martínez,
                                    Alexis Palacios‐Macedo, Rogelio Cruz‐Martínez. Prenatal diagnosis,
                                    2019.
                                </p>
                            </li>
                            <li>
                                <a href="https://dx.doi.org/10.31403/rpgo.v64i2141">
                                    Cirugía fetal para hernia diafragmática congénita en América Latina.
                                </a>
                                <p>
                                    <b>
                                        R Cruz-Martínez</b>. Revista Peruana de Ginecología y Obstetricia, 2018 2018.
                                </p>
                            </li>
                            <li>
                                <a href="https://www.medigraphic.com/cgi-bin/new/resumen.cgi?IDARTICULO=86411&amp;id2=">
                                    Secuestro broncopulmonar. Diagnóstico prenatal, factores pronóstico y tratamiento por cirugía fetal.
                                </a>
                                <p>
                                    <b>
                                        R Cruz-Martínez,
                                    </b>
                                    Ordorica-Flores R. GinecologíayObstetricia, 2019.
                                </p>
                            </li>
                            <li>
                                <a href="https://doi.org/10.1002/uog.21993">
                                    Survival outcomes in severe left diaphragmatic hernia with and without fetoscopic tracheal occlusion in a country with suboptimal
                                    neonatal management.
                                </a>
                                <p>
                                    A. Gámez-Varela, B Nieto-Castro, J. Luna-García, I. Juárez-Martínez, H. López-Briones, R. Guadarrama-Mora, J. Torres-Torres,
                                    F. Coronel-Cruz, D. Ibarra-Rios, R. Ordorica-Flores, J. Nieto-Zermeño. Ultrasound Obstet Gynecol. 2020.
                                </p>
                            </li>
                            <li>
                                <a href="https://doi.org/10.1080/14767058.2019.1638905">
                                    Prenatal diagnosis of congenital heart defects: experience of the first Fetal Cardiology Unit in Mexico.
                                </a>
                                <p>
                                    Monica Cruz-Lemini, Belen Nieto-Castro, Jonahtan Luna-Garcia, Israel Juarez-Martinez, Magdalena Martínez-Rivera, Ma.
                                    de la Luz Bermudez-Rojas, Carlos Rebolledo-Fernández Rogelio Cruz-Martinez. The Journal of Maternal-Fetal Neonatal
                                    Medicine, 2019.
                                </p>
                            </li>
                            <li>
                                <a href="https://doi.org/10.1002/pd.5458">
                                    A multicentre study to predict neonatal survival according to lung‐to‐head ratio and liver herniation in
                                    fetuses with left congenital diaphragmatic hernia (CDH): Hidden mortality from the Latin American CDH Study
                                    Group Registry.
                                </a>
                                <p>
                                    <b>
                                        Rogelio Cruz‐Martínez,
                                    </b>
                                    Adolfo Etchegaray, Saulo Molina‐Giraldo, Belen Nieto‐Castro, Enrique Gil Guevara, Joaquin Bustillos, Miguel Martínez‐Rodríguez,
                                    Alma Gámez‐Varela, Daniel Saldivar‐Rodríguez, Erendira Chávez‐González, Rodolfo Keller, Ricardo Russo, Eduardo Yepez‐García, Fausto
                                    Coronel‐Cruz, Johnatan Torres‐Torres, Alejandro Rojas‐Macedo, Daniel Ibarra‐Ríos, Ricardo Ordorica‐Flores, Jaime Nieto‐Zermeño,
                                    Manuel Alcocer‐Alcocer on behalf of the Latin American CDH Study Group. Prenatal diagnosis, 2019.
                                </p>
                            </li>
                            <li>
                                <a href="https://doi.org/10.1002/pd.5470">
                                    Outcomes of hypoplastic left heart syndrome and fetal aortic valvuloplasty in a country with suboptimal postnatal management.
                                </a>
                                <p>
                                    Mónica Cruz‐Lemini, Monica Alvarado‐Guaman, Belen Nieto‐Castro, Jonathan Luna‐Garcia, Miguel Martínez‐Rodríguez,
                                    Israel Juarez‐Martínez, Alexis Palacios‐Macedo, Rogelio Cruz‐Martínez. Ultrasound Obstet Gynecol, 2019.
                                </p>
                            </li>
                            <li>
                                <a href="https://doi.org/10.31403/rpgo.v64i2141">
                                    Cirugía fetal para hernia diafragmática congénita en América Latina.
                                </a>
                                <p>
                                    <b>
                                        Rogelio Cruz-Martínez.
                                    </b>
                                    Fetal surgery for congenital diaphragmatic hernia in Latin America. Revista Peruana de Ginecología y
                                    Obstetricia, 2018.
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default Investigation;
import React from 'react';
import "./styles/Productivity.css"

function ComprehensivePrivacy() {
    return (
        <div className="site-content">
            <main>
                <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
                <div style={{ width: "80%", margin: "0px auto" }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={process.env.PUBLIC_URL + "/img/fundacionlogo.png"} style={{ maxWidth: "20%" }} />
                    </div>
                    <div className="section-title">
                        <h2>AVISO DE PRIVACIDAD</h2>
                        <hr />
                    </div>
                    <p>
                        De conformidad con las disposiciones establecidas en la Ley Federal de Protección de Datos Personales en Posesión de Particulares (la “Ley”), y
                        demás relacionadas, nos permitimos informarle mediante este documento, sobre las características y finalidades del tratamiento que se dará a sus
                        datos personales que obran en posesión de “<strong>FUNDACIÓN MEDICINA FETAL MÉXICO” A.C.</strong>, la “Fundación”.
                    </p>
                    <div className="section-title">
                        <h2>1. Identidad y domicilio del responsable</h2>
                        <hr />
                    </div>
                    <p>
                        “FUNDACIÓN MEDICINA FETAL MÉXICO” A.C., en una asociación civil constituida conforme a las Leyes de los Estados Unidos Mexicanos, en lo sucesivo
                        denominada como la “Fundación”, se encuentra ubicada en: Blvd. Bernardo Quintana Núm. 4060, Int. 508, Col. San Pablo, 76125, Querétaro, Querétaro,
                        México, con número de teléfono: 442 168 78 79 y correo electrónico: donacionesfundacion@medicinafetalmexico.com, hace de su conocimiento la Política
                        de Protección de Datos de Carácter Personal, en cumplimiento a la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, y
                        le informa que sus datos personales, que hayan sido recabados actualmente o sean recabados en el futuro, serán tratados y/o utilizados por la
                        Fundación, por los capacitadores de la Fundación, para fines de dichas capacitaciones, y/o por aquellos terceros que, por la naturaleza de su
                        trabajo o funciones, tengan necesidad de tratar y/o utilizar sus datos personales.
                    </p>
                    <div className="section-title">
                        <h2>2. ¿Para qué fines utilizaremos sus datos personales?</h2>
                        <hr />
                    </div>
                    Los datos personales que la “Fundación” obtiene, almacena y usa, le son proporcionados de manera voluntaria por usted para las siguientes
                    finalidades:
                    <ul>
                        <li>Confirmar su identidad</li>
                        <li>Darlo de alta en nuestros sistemas como participantes o prospecto de participante</li>
                        <li>Contactarlo y hacerle llegar información de nuestros programas académicos y del boletín mensual vía digital</li>
                        <li>Proporcionarle el curso online que solicite o contrate con nosotros y realizar examen en línea</li>
                        <li>Efectuar el cobro correspondiente y generar el comprobante fisca</li>
                        <li>Otorgarle beneficios</li>
                        <li>Dar cumplimiento a las obligaciones contraídas con usted y ejercer nuestros derechos</li>
                    </ul>
                    <p>
                        Adicionalmente, su información personal podrá ser utilizada con las siguientes finalidades secundarias, las cuales no son necesarias para brindarle
                        nuestros servicios, pero pueden resultar en su interés, y contribuyen a desarrollar nuestros legítimos intereses altruistas y/o académicos:
                    </p>
                    <ul>
                        <li>Evaluar la calidad del servicio que le brindamos</li>
                        <li>Invitarlo a congresos y programas académicos</li>
                        <li>Realizar actividades publicitarias, promocionales y mercadotécnicas</li>
                    </ul>
                    <p>
                        Si usted no desea que sus datos personales sean tratados para las finalidades secundarias señaladas, o alguna de ellas, puede negarnos su
                        consentimiento desde este momento enviando su solicitud a nuestro oficial de privacidad. Su negativa no será motivo para dejar de proporcionarle
                        nuestros servicios y realizar las demás finalidades.
                    </p>
                    <div className="section-title">
                        <h2>3. ¿Qué datos personales recabamos y utilizamos sobre usted?</h2>
                        <hr />
                    </div>
                    <p>
                        <strong>
                            Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos personales:
                        </strong>
                        <p>
                            Datos Personales de Identificación: Nombre completo, Fecha de Nacimiento, Edad, Nacionalidad, Género, Domicilio, Teléfono
                            (Casa, Celular, Oficina), Estado Civil, Correo electrónico.
                        </p>
                        <p>
                            Datos Personales Patrimoniales: Cuentas bancarias, créditos, entre otros.
                        </p>
                        <p>
                            La información que proporcione deberá ser veraz y completa. Por lo que queda bajo su responsabilidad la veracidad de los datos
                            proporcionados y en ningún caso “la FUNDACIÓN” será responsable a este respecto.
                        </p>
                        <p>
                            La información proporcionada ya sea de manera directa personal, en nuestro portal Web
                            (<a href="https://fundacionmfm.com/">https://fundacionmfm.com/</a>)
                            correo electrónico, teléfono, o bien por fuentes de acceso público y de otras fuentes disponibles en el mercado, estará sujeta a lo
                            establecido en el presente Aviso de Privacidad.
                        </p>
                    </p>
                    <div className="section-title">
                        <h2>4. ¿Qué datos personales sensibles utilizaremos?</h2>
                        <hr />
                    </div>
                    <p>
                        Para el cumplimento de los objetivos antes mencionados, podemos recabar sus datos personales sensibles por diferentes vías. Usualmente recabamos
                        sus datos cuando usted nos los proporciona directamente (a través de nuestros empleados, colaboradores, procuradores de fondos o de nuestra página
                        de Internet). En ocasiones, recabamos sus datos de manera indirecta, a través de otras fuentes permitidas por la Ley.
                    </p>
                    <p>
                        Los datos personales sensibles que obtenemos pueden ser: Clave Única de Registro de Población, Registro Federal de Contribuyentes, Credencial de
                        Elector y/o datos bancarios para cargar su donativo. Además.
                    </p>
                    <p>
                        <strong>
                            Otorgo mi consentimiento para que mis datos personales sensibles sean tratados conforme a lo señalado en el presente aviso de privacidad
                        </strong>
                    </p>
                    <div id="numeroCinco">
                        <div className="section-title">
                            <h2>
                                5. ¿Con quién compartimos su información y para qué fines?
                            </h2>
                            <hr />
                        </div>
                        <p>
                            La Fundación podrá transferir los datos personales que obren en sus bases de datos a terceras personas, nacionales o extranjeras, como pudieran ser de manera
                            enunciativa, mas no limitativa:
                        </p>
                        <table>
                            <thead>
                                <td>
                                    <strong>Destinatario de los Datos Personales</strong>
                                </td>
                                <td>
                                    <strong>Finalidad</strong>
                                </td>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        Empresas, instituciones y Organizaciones con los que tengamos un contrato celebrado o por celebrar que sea en su interés
                                    </td>
                                    <td>
                                        Con la finalidad de otorgarle beneficios y para completar nuestros resultados investigativos que tiene como fin primordial el
                                        bienestar de la sociedad
                                    </td>
                                </tr>
                                <tr>
                                    <td>Autoridades competentes</td>
                                    <td>Para las siguientes finalidades: Cumplir con un mandato debidamente fundado y motivado; cumplir con disposiciones legales o
                                        reglamentarias; ejercicio o defensa de un derecho en un proceso judicial</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            Se requiere su consentimiento expreso para el caso de que sus datos personales y datos personales sensibles sean transferidos a
                            los terceros señalados anteriormente</p>
                        <p style={{ textAlign: "center" }}>
                            <strong>Si doy mi consentimiento para la transferencia de mis datos.</strong>
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong> No doy mi consentimiento para la transferencia de mis datos.</strong>
                        </p>
                    </div>
                    <div className="section-title">
                        <h2>6. ¿Cómo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su uso?</h2>
                        <hr />
                    </div>
                    <p>
                        El titular de los datos personales, podrán ejercer sus derechos Acceso, Rectificación, Cancelación u Oposición (Derechos ARCO),
                        mismos que se encuentran consagrados en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, presentando
                        una solicitud por escrito en nuestro domicilio, ubicado en: Blvd. Bernardo Quintana Núm. 4060, Int. 508, Col. San Pablo, 76125,
                        Querétaro, Querétaro, México</p><p>Con la finalidad de poder atender su solicitud, ésta deberá satisfacer todos los requisitos
                            estipulados en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, como son:
                    </p>
                    <ul>
                        <li>
                            El nombre del titular, domicilio, número telefónico o correo electrónico para comunicarle la respuesta a su solicitud
                        </li>
                        <li>Los documentos que acrediten la identidad o, en su caso la representación legal del titular</li>
                        <li>La descripción clara y precisa de los Datos Personales respecto de los que se busca ejercer alguno de los derechos antes mencionados
                            (en caso de modificación se deberá indicar las modificaciones a realizar y aportar la documentación que sustente su petición)
                        </li>
                        <li>Cualquier otro documento o elemento que facilite la localización de datos personales</li>
                    </ul>
                    <p>
                        Los documentos deberán ser escaneados y adjuntados al correo electrónico para verificar la veracidad de estos.
                    </p>
                    <div className="section-title">
                        <h2>7. ¿Cómo puede revocar su consentimiento para el uso de sus datos personales?</h2>
                        <hr />
                    </div>
                    <p>
                        Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales.
                        Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de
                        forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos personales. Asimismo,
                        usted deberá considerar que, para ciertos fines, la revocación de su consentimiento implicará que no le podamos seguir
                        prestando el servicio que nos solicitó, o la conclusión de su relación con nosotros.
                    </p>
                    <p>
                        Para revocar su consentimiento deberá presentar una solicitud por escrito en nuestro domicilio, ubicado en: Blvd. Bernardo Quintana Núm. 4060,
                        Int. 508, Col. San Pablo, 76125, Querétaro, Querétaro, México.
                    </p>
                    <p>
                        El procedimiento y requisitos para la revocación del consentimiento es el siguiente:
                        <ul>
                            <li>
                                Una vez recibida su solicitud para ejercer sus derechos ARCO debidamente integrada y con los requisitos legales vigentes, recibirá
                                contestación de nuestra parte en un plazo no mayor a 20 días hábiles.
                            </li>
                            <li>
                                Si resultara procedente, se hará efectiva dentro de los 15 días hábiles siguientes a que le comunicamos nuestra respuesta
                            </li>
                            <li>
                                En caso de estar inconforme con la respuesta notificada, tendrá un plazo de 20 días hábiles para ponerse en contacto con nosotros, indicando
                                su inconformidad o inquietud, con la finalidad de resolver dicha situación
                            </li>
                            <li>
                                En caso de no responder nuestra contestación en el plazo señalado, LA “FUNDACIÓN” entiende de buena fe que está conforme con nuestra
                                respuesta
                            </li>
                        </ul>
                    </p>
                    <div className="section-title">
                        <h2>8. ¿Cómo puede limitar el uso o divulgación de su información personal? Con objeto de que usted pueda limitar el uso y
                            divulgación de su información personal, le ofrecemos los siguientes medios:
                        </h2>
                        <hr />
                    </div>
                    <p>
                        Además del procedimiento contemplado en la sección inmediata anterior, usted puede limitar el uso o divulgación de sus datos personales,
                        registrándose en el “Listado de Exclusión Publicitaria para Participantes o asistentes de la “FUNDACIÓN MEDICINA FETAL MÉXICO” A.C.,
                        con el objeto de que no utilicemos su información personal para finalidades publicitarias, promocionales, y mercadotécnicas.
                        Para solicitar su registro, así como para obtener más información al respecto, usted puede contactar a nuestro oficial de privacidad vía correo electrónico.</p><p>Su inscripción en el Registro Público para Evitar Publicidad, que está a cargo de la Procuraduría Federal del Consumidor, con la finalidad de que sus datos personales no sean utilizados para recibir publicidad o promociones de empresas de bienes o servicios. Para más información sobre este registro, usted puede consultar el portal de Internet de la
                        <a href="https://www.gob.mx/profeco">PROFECO</a> (https://www.gob.mx/profeco), o bien ponerse en contacto directo con ésta.
                    </p>
                    <div className="section-title">
                        <h2>9. El uso de tecnologías de rastreo en nuestro portal de Internet.</h2>
                        <hr />
                    </div>
                    <p>
                        Le informamos que en nuestra página de Internet utilizamos <strong>COOKIES, WEB BEACONS</strong> y otras tecnologías a través de las
                        cuales es posible monitorear su comportamiento como usuario de Internet, brindarle un mejor servicio y experiencia de usuario al navegar
                        en nuestra página, así como ofrecerle nuevos productos y servicios basados en sus preferencias.</p><p>Los datos personales que obtenemos
                            de estas tecnologías de rastreo son los siguientes: horario de navegación, tiempo de navegación en nuestra página de Internet,
                            secciones consultadas, y páginas de Internet accedidas previo a la nuestra.
                    </p>
                    <p>
                        Asimismo, le informamos que sus datos personales que se obtienen a través de estas tecnologías los compartiremos con personas,
                        empresas, organizaciones o autoridades distintas a nosotros, para los siguientes fines y con previa autorización de usted, de acuerdo
                        <a href="#numeroCinco"> apartado número 5</a> del presente aviso.
                    </p>
                    <p>
                        Estas tecnologías podrán deshabilitarse siguiendo los siguientes pasos: 1. Acceder a nuestra página de Internet, sección “Términos y
                        condiciones del sitio”, subsección “Cookies”; 2. Dar clic en la subsección “Cookies”; 3. Leer el mensaje de advertencia sobre la
                        deshabilitación de cookies, y 4. Dar clic en la leyenda de activar el mecanismo de deshabilitación de cookies.
                    </p>
                    <p>
                        Para ejemplificar lo anterior, en las siguientes ligas se encuentra la forma de cómo hacerlo para estos tipos de navegadores:
                        <ul>
                            <li><a href="http://windows.microsoft.com/es-es/windows-vista/block-or-allow-cookies">Desde Internet Explorer (en Windows)</a>
                            </li>
                            <li><a href="xhttps://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we">Firefox</a>
                            </li>
                            <li>
                                <a href="http://support.google.com/chrome/bin/answer.py?hl=es&amp;answer=95647">Google Chrome</a>
                            </li>
                            <li>
                                <a href="http://www.apple.com/legal/privacy/es/cookies/">Safari en Mac</a>
                            </li>
                        </ul>
                    </p>
                    <p>
                        Para más información, así como para atender cualquier duda o comentario, usted podrá contactarse vía correo electrónico con nuestro
                        oficial de privacidad.
                    </p>
                    <div className="section-title">
                        <h2>10. Modificaciones al Aviso de Privacidad. ¿Cómo puede conocer los cambios a este aviso deprivacidad?</h2>
                        <hr />
                    </div>
                    <p>
                        El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras
                        propias necesidades por los productos o servicios que ofrecemos; de nuestras prácticas de privacidad o por otras causas.
                    </p>
                    <p>
                        El procedimiento con relación a la notificación vía Internet es el siguiente: (i) Ingrese a nuestra página de Internet: fundacionmfm.com.
                        En caso de haber modificaciones o actualizaciones al presente aviso de privacidad, habrá una notificación que se lo haga saber; (ii) acceda a la
                        liga de aviso de privacidad; (iii) ahí encontrará la versión vigente con la fecha de la más reciente actualización.
                    </p>
                    <div className="section-title">
                        <h2>11. Quejas y denuncias por el tratamiento indebido de datos personales</h2>
                        <hr />
                    </div>
                    <p>
                        Si usted considera que su derecho de protección de datos personales ha sido lesionado por alguna conducta de nuestros empleados,
                        encargados o de nuestras actuaciones o respuestas, y presume que en el tratamiento de sus datos personales existe alguna violación a las
                        disposiciones previstas en la Ley, podrá interponer la queja o denuncia correspondiente ante el Instituto Nacional de Transparencia,
                        Acceso a la Información y Protección de Datos Personales (“INAI”), para mayor información visite
                        <a href=" https://home.inai.org.mx/"> https://home.inai.org.mx/</a>.
                    </p>
                    <div className="section-title">
                        <h2>12. Consentimiento expreso y por escrito</h2>
                        <hr />
                    </div>
                    <p>
                        Hacemos de su conocimiento que sus datos personales y datos personales sensibles serán resguardados bajo estrictas medidas de
                        seguridad administrativas, técnicas y físicas las cuales han sido implementadas con el objeto de proteger sus datos personales
                        contra daño, pérdida, alteración, destrucción o el uso o acceso o tratamiento no autorizado
                    </p>
                    <p>
                        La Web Site de LA “FUNDACIÓN” puede llegar a conectar con ciertos links de otras páginas Web que pertenecen a terceros sobre los que LA
                        “FUNDACIÓN” no tiene control alguno. En estos supuestos LA “FUNDACIÓN” no asume responsabilidad alguna ni compromiso sobre las políticas de
                        privacidad de la información contenidas en esas páginas.
                    </p>
                    <p>
                        Esta declaración de Privacidad está sujeta a la Política de Privacidad de LA “FUNDACIÓN” lo cual constituye un acuerdo legal entre usted y LA
                        “FUNDACIÓN”.
                    </p>
                    <p>
                        Al navegar en el sitio Web de LA “FUNDACIÓN”, significa que ha leído, entendido y está de acuerdo con los términos antes expuestos.
                    </p>
                    <p>
                        <strong>
                            Otorgo mi consentimiento para que mis datos personales sean tratados conforme a lo señalado en el presente aviso de privacidad.
                        </strong>
                    </p>
                    <p>
                        <strong>Se declara que se encuentra de acuerdo con el presente aviso.</strong>
                    </p>
                    <div className="section-title">
                        <h2>13. Datos de Contacto</h2>
                        <hr />
                    </div>
                    <p>
                        En caso de tener dudas o comentarios respecto del presente Aviso de Privacidad, así como para ejercitar sus derechos ARCO le
                        proporcionamos nuestros datos de contacto: “FUNDACIÓN MEDICINA FETAL MÉXICO” A.C. Domicilio: Blvd. Bernardo Quintana Núm. 4060, Int.
                        508, Col. San Pablo, 76125, Querétaro, Querétaro, México
                    </p>
                    <p>Teléfono: <a href="tel:+524421687879">442 168 78 79</a></p>
                    <p>Correo:<a href="mailto:donacionesfundacion@medicinafetalmexico.com">donacionesfundacion@medicinafetalmexico.com</a></p>
                    <p>Última actualización: 12 de octubre de 2021.</p>
                </div>
            </main>
        </div>
    );
}

export default ComprehensivePrivacy;
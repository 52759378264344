import React from 'react';
import "./styles/Forum.css"

function Forum() {
    const showObject = async () => {

    }
    return (
        <div className="site-content">
            <main>
                <div className="content-body about-page">
                    <div className="section-title">
                        <h2>Foro</h2>
                        <hr />
                    </div>
                    <div data-accordion-component="Accordion" className="accordion">
                        <div data-accordion-component="AccordionItem" className="accordion__item">
                            <div data-accordion-component="AccordionItemHeading" role="heading" className="accordion__heading">
                                <div className="accordion__button" id="accordion__heading-raa-0" aria-disabled="false" aria-expanded="false" aria-controls="accordion__panel-raa-0" role="button" data-accordion-component="AccordionItemButton">
                                    ¿Cuánto peso es normal subir durante el embarazo?
                                </div>
                            </div>
                            <div data-accordion-component="AccordionItemPanel" className="accordion__panel" role="region" aria-labelledby="accordion__heading-raa-0" id="accordion__panel-raa-0" >
                                <p>
                                    Es importante considerar que cada una somos diferentes
                                    por lo que el peso que podemos subir es individual en cada caso.&nbsp; Saber el peso que tenias antes del embarazo,
                                    es crucial para calcular el Índice de Masa Corporal (IMC= peso/ talla2).
                                    Si tu índice de masa corporal era bajo (&lt;18.5) podría incrementar entre 12 y
                                    18 kg. Con un índice normal (18.5-24.9) entre 11 a 15 kg, con un índice de 25 a
                                    29.9 puede ser entre 6 y 11 kg y si tu índice era mayor deberá ser entre 4 a 6
                                    kg. </p><p>

                                </p>
                                <p>Referencia: J Acad Nutr Diet. 2016;116:677-691</p>
                                <p>
                                </p>
                            </div>
                        </div>
                        <div data-accordion-component="AccordionItem" className="accordion__item">
                            <div data-accordion-component="AccordionItemHeading" role="heading" className="accordion__heading">
                                <div className="accordion__button" id="accordion__heading-raa-1" aria-disabled="false" aria-expanded="false" aria-controls="accordion__panel-raa-1" role="button" data-accordion-component="AccordionItemButton">
                                    ¿Cada cuánto es necesario hacerme ultrasonidos?
                                </div>
                            </div>
                            <div data-accordion-component="AccordionItemPanel" className="accordion__panel" role="region" aria-labelledby="accordion__heading-raa-1" id="accordion__panel-raa-1" >
                                <p>
                                </p>
                                <p>
                                    El objetivo de
                                    los ultrasonidos, es realizar una exploración detallada que nos permita tener
                                    la tranquilidad que todo marcha adecuadamente con nuestro embarazo, por lo que
                                    se recomienda por lo menos 3. El primero debe hacerse entre las 11 a 13.6 sdg ,
                                    el segundo entre las 20 a 24 sdg&nbsp; después
                                    de la semana 30. En muchas ocasiones es necesario hacer más estudios incluso
                                    llegar a ser semanales, como es el caso de los embarazos de gemelos. Siempre es
                                    importante seguir las recomendaciones de tu médico.
                                </p>
                                <p>
                                </p>
                                <p>
                                    Referencia: Gynecol
                                    Obstet Fertil. 2014 Jan;42(1):8-13
                                </p>
                                <p>
                                </p>
                            </div>
                        </div>
                        <div data-accordion-component="AccordionItem" className="accordion__item">
                            <div data-accordion-component="AccordionItemHeading" role="heading" className="accordion__heading">
                                <div className="accordion__button" id="accordion__heading-raa-2" aria-disabled="false" aria-expanded="false" aria-controls="accordion__panel-raa-2" role="button" data-accordion-component="AccordionItemButton">
                                    ¿Es normal que le de hipo a mi bebé?
                                </div>
                            </div>
                            <div data-accordion-component="AccordionItemPanel" className="accordion__panel" role="region" aria-labelledby="accordion__heading-raa-2" id="accordion__panel-raa-2">
                                <p>
                                    Es totalmente normal, suele ser más común el hipo durante el primer trimestre pero es tan pequeño el bebé que no podemos
                                    percibirlo. Suele ser menos frecuente durante el segundo y tercer trimestre, pero por su tamaño es percibido con mayor
                                    facilidad.
                                </p>
                                <p>
                                </p>
                                <p>
                                    Referencia: J Matern Fetal Neonatal Med. 2017 Feb;30(4):475-478.
                                </p>
                                <p>
                                </p>
                                <p>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <br />
                        <div className="section-title">
                            <h2>Contáctanos</h2>
                            <hr />
                            <form
                                action="https://formsubmit.co/fundacion@medicinafetalmexico.com"
                                method="POST"
                            >
                                <input
                                    className="form-styling"
                                    type="text"
                                    name="Nombre"
                                    placeholder="Nombre completo"
                                    required
                                />
                                <input
                                    className="form-styling"
                                    type="email"
                                    name="Email"
                                    placeholder="Correo electrónico"
                                    required
                                />
                                <textarea
                                    className="form-styling"
                                    name="Pregunta"
                                    placeholder="Déjanos tu pregunta"
                                    style={{ height: "120px" }}
                                    required
                                ></textarea>
                                <input type="hidden" name="_template" value="box"></input>
                                <div className="button-container">
                                    <center>
                                        <button type="submit" className="button button-cta">
                                            Enviar
                                        </button>
                                    </center>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default Forum;
import React from 'react';
import "./styles/Support.css"

function Support() {
    return (
        <>
            <div className="content-body support-page">
                <div className="section-title">
                    <h2>Cómo apoyar</h2>
                    <hr />
                </div>
                <div className="support-card">
                    <div>
                        <p>
                            ¿Deseas apoyar a una familia?</p>
                    </div>
                    <div className="donations-card">
                        <div className="circle-up">
                        </div>
                        <div className="circle-bot"></div>
                        <p>
                            <b>
                                Nombre de la fundación:
                            </b>
                            Fundación Medicina Fetal México A.C
                        </p>
                        <p>
                            <b>
                                Banco:
                            </b>
                            BANORTE
                        </p>
                        <p>
                            <b>
                                No. Cuenta:
                            </b>
                            1219889884
                        </p>
                        <p>
                            <b>
                                CLABE:
                            </b>
                            072 320 012198898846
                        </p>
                    </div>
                    <p>
                        ¡Contáctanos! Escribe un mail a
                        <a className="secondary-link" href="mailto:donacionesfundacion@medicinafetalmexico.com?Subject=Nueva%20Pregunta">donacionesfundacion@medicinafetalmexico.com</a>
                        o llámanos al 4421687879.
                    </p>
                </div>
                <div className="row button-container">
                    <a className="button button-cta" href="mailto:donacionesfundacion@medicinafetalmexico.com?Subject=Nueva%20Pregunta">
                        Enviar Correo Electrónico
                    </a>
                    <a className="button button-cta" href="tel:4421957014">
                        Llamar vía Teléfono
                    </a>
                </div>
                <br />
                <p>
                    No olvides de seguirnos en nuestras redes sociales:
                </p>
                <div className="social-media-container">
                    <a href="https://www.facebook.com/fundacionmedfetalmexico" />
                    <img />
                    <img />
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "15px" }}>
                    <div className="social-media-container">
                        <a href="https://www.facebook.com/fundacionmedfetalmexico">
                            <img src={process.env.PUBLIC_URL + "/img/facebook.png"} style={{ maxWidth: "20px" }} />
                        </a>
                    </div>
                    <div className="social-media-container">
                        <a href="https://www.instagram.com/fundacionmedicinafetalmexico/">
                            <img src={process.env.PUBLIC_URL + "/img/instagram.png"} style={{ maxWidth: "30px" }} />
                        </a>
                    </div>
                </div>
                <br /><br /><br />
                <div className="section-title"><h2>Nuestros Patrocinadores</h2><hr /></div>
                <div className="images-container">
                    <img src={process.env.PUBLIC_URL + "/img/dif.png"}/>
                    <img src={process.env.PUBLIC_URL + "/img/fetalmed.png"}/>
                    <img src={process.env.PUBLIC_URL + "/img/premiumSystem.jpeg"}/>
                    <img src={process.env.PUBLIC_URL + "/img/kimberly.jpeg"}/>
                    <img src={process.env.PUBLIC_URL + "/img/defestatal.jpeg"}/>
                    <img src={process.env.PUBLIC_URL + "/img/cemefi.png"}/>
                    <img src={process.env.PUBLIC_URL + "/img/OXO.png"}/>
                </div>
            </div>
        </>
    );
}

export default Support;
import React from 'react';
import "./styles/Neurodevelopment.css"

function Neurodevelopment() {
    return (
        <div className="site-content">
            <main>
                <div className="content-body neuro-page">
                    <div className="section-title">
                        <h2>Neurodesarrollo</h2>
                        <hr />
                    </div>
                    <div className="hero-image">
                        <img src={process.env.PUBLIC_URL + "/img/neurodesarrollo.jpg"} />
                    </div>
                    <div>
                        <p>
                            La Fundación Medicina Fetal México A.C está comprometida con el desarrollo de tu(s) bebé(s) desde los inicios de su vida hasta la
                            infancia.
                        </p>
                        <p>
                            Mamá y Papá…. Si tus bebés desarrollaron en vida fetal una patología y debido a esto recibieron terapia fetal (Cirugía fetal), comunícate con nosotros si
                            recibiste apoyo de la fundación o la cirugía fetal se realizó en las instalaciones del Hospital del niño y la mujer en Querétaro y conoce todos
                            los beneficios del programa.
                        </p>
                        <p>
                            ¡No te quedes fuera, nos encantaría conocer y saber de ustedes!
                        </p>
                        <p>
                            Los beneficios:
                            <b>
                                El programa incluye un seguimiento del desarrollo psicomotriz: cognitivo, lenguaje, social y motor.
                            </b>
                            Por medio de evaluaciones podemos determinar si tu bebé se encuentra dentro de los limites adecuados del desarrollo, permitiendo obtener un
                            diagnostico e intervención temprana.
                        </p>
                        <p>
                            El diagnóstico y la atención temprana consisten en proporcionar al bebé y al niño las mejores oportunidades de desarrollo físico, intelectual y
                            social, con el objetivo de que sus capacidades y habilidades se encuentre dentro de los límites normales.
                        </p>
                        <p>
                            <b>
                                ¡Importante!
                            </b>
                            Las evaluaciones de seguimiento de neurodesarrollo en las instalaciones de la Fundación son
                            <b>
                                gratuitas
                            </b>
                            .
                        </p>
                    </div>
                    <div className="image-grid">
                        <div style={{ display: "flex", gap: "15px", flexWrap: "wrap"}}>
                            <img src={process.env.PUBLIC_URL + "/img/neurodesarrollo2.jpg"} style={{ width: "100%", maxWidth: "350px" }} />
                            <img src={process.env.PUBLIC_URL + "/img/neurodesarrollo3.jpg"} style={{ width: "100%", maxWidth: "350px" }} />
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default Neurodevelopment;